import React from 'react'
import MainPage from './pages/main'

const App: React.FC = () => {
  return (
    <MainPage />
  );
}

export default App
